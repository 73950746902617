export function hsbToRgb(hue, saturation, brightness, alpha = 1) {
    saturation /= 100;
    brightness /= 100;
    let i = Math.floor(hue / 60);
    let f = hue / 60 - i;
    let p = brightness * (1 - saturation);
    let q = brightness * (1 - saturation * f);
    let t = brightness * (1 - saturation * (1 - f));
    let r, g, b;
    switch (i % 6) {
        case 0:
            [r, g, b] = [brightness, t, p];
            break;
        case 1:
            [r, g, b] = [q, brightness, p];
            break;
        case 2:
            [r, g, b] = [p, brightness, t];
            break;
        case 3:
            [r, g, b] = [p, q, brightness];
            break;
        case 4:
            [r, g, b] = [t, p, brightness];
            break;
        case 5:
            [r, g, b] = [brightness, p, q];
            break;
    }
    return `rgba(${Math.round(r * 255)}, ${Math.round(g * 255)}, ${Math.round(b * 255)}, ${alpha})`;
}
