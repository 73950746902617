import react from 'react';
import * as Material from '@mui/material';
import { NavbarComponent } from '../common/navbar';
import { fetchAddUserToWaitlist, fetchCreateVerification } from '../../services/apiService';
import styled from 'styled-components';
import SmsCodeVerification from '../common/verification';

export const HomeScreen = ({ }) => {
    const [email, setEmail] = react.useState('');
    const [phone, setPhoneNumber] = react.useState('');
    const [sentVerification, setSentVerification] = react.useState(false);

    const handleSubmitBtn = async () => {
        if (email === '' || phone === '') return alert('Please fill out all fields.');

        const result = await fetchCreateVerification(phone);
        if (!result) return alert('Something went wrong. Please try again later.');

        await setSentVerification(true);
        return;
    }

    const handleInputChange = (event) => {
        const input = event.target.value;
        const formattedInput = input.replace(/\D/g, '').slice(0, 10); // Remove non-digit characters and limit to max 10 digits
        const formattedPhoneNumber = formattedInput.replace(/(\d{3})(\d{3})(\d{4})/, '$1$2$3'); // Format as XXXXXXXXXX

        setPhoneNumber(formattedPhoneNumber);
    };

    if (sentVerification) {
        return (
            <div className='App' style={{ height: '100%', minHeight: '100vh' }}>
                <div>
                    <NavbarComponent />
                </div>

                <CustomSmallBox>
                    <div id='Top' style={{}}>
                        <div id='Image' style={{ marginTop: 10, width: '100%' }}>
                            <div id='Container' style={{ marignLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
                                <img src='https://storage.googleapis.com/waves_general_bucket/waiting.png' style={{ height: 'auto', width: 'auto', maxHeight: 100, maxWidth: 100 }} />
                            </div>
                        </div>
                        <div id='Title' style={{ marginTop: 25, width: '100%' }}>
                            <div id='Container' style={{ marignLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
                                <h1 className='font-link:Poppins' style={{ color: '#fff', fontSize: 30, letterSpacing: 1, margin: 0, padding: 0 }}>Verify your phone number.</h1>
                                <p className='font-link:Poppins' style={{ color: '#fff', fontSize: 15, letterSpacing: 1, margin: 0, paddingTop: 5, textDecorationLine:'underline' }}>Didn't recieve a code? Try again.</p>
                            </div>
                        </div>

                        <div id='Collection' style={{ marginTop: '5%', width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                            <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                <SmsCodeVerification phoneNumber={phone} email={email} />
                            </div>
                        </div>
                    </div>
                </CustomSmallBox>
            </div>
        )
    }


    return (
        <div className='App' style={{ height: '100%', minHeight: '100vh' }}>
            <div>
                <NavbarComponent />
            </div>
        </div>
    );
};

/*
            <CustomSmallBox>
                <div id='Top' style={{}}>
                    <div id='Image' style={{ marginTop: 10, width: '100%' }}>
                        <div id='Container' style={{ marignLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
                            <img src='https://storage.googleapis.com/waves_general_bucket/waiting.png' style={{ height: 'auto', width: 'auto', maxHeight: 100, maxWidth: 100 }} />
                        </div>
                    </div>
                    <div id='Title' style={{ marginTop: 25, width: '100%' }}>
                        <div id='Container' style={{ marignLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
                            <h1 className='font-link:Poppins' style={{ color: '#fff', fontSize: 30, letterSpacing: 1, margin: 0, padding: 0 }}>Join the WAITLIST.</h1>
                            <p className='font-link:Poppins' style={{ color: '#fff', fontSize: 15, letterSpacing: 1, margin: 0, paddingTop: 5 }}>We'll let you know when we're ready.</p>
                        </div>
                    </div>
                </div>

                <div id='Collection' style={{ marginTop: 25, width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                    <div id='Email' style={{}}>
                        <div id='Container' style={{ marignLeft: 'auto', marginRight: 'auto' }}>
                            <p style={{ margin: 0, padding: 0, fontSize: 12, color: 'lightgrey' }}>Enter your email.</p>
                        </div>
                        <div id='Container' style={{ marignLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
                            <Material.TextField placeholder='Email' required value={email} onChange={(e) => setEmail(e.target.value)} id='email' size='small' variant='outlined' style={{ width: '100%', marginTop: 10, background: 'whitesmoke', borderRadius: 5 }} />
                        </div>
                    </div>

                    <div id='Phone' style={{ marginTop: 15 }}>
                        <div id='Container' style={{ marignLeft: 'auto', marginRight: 'auto' }}>
                            <p style={{ margin: 0, padding: 0, fontSize: 12, color: 'lightgrey' }}>Enter your phone number.</p>
                        </div>
                        <div id='Container' style={{ marignLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
                            <Material.TextField placeholder='Phone' required value={phone} onChange={handleInputChange} id='email' size='small' variant='outlined' style={{ width: '100%', marginTop: 10, background: 'whitesmoke', borderRadius: 5 }} />
                        </div>
                    </div>

                    <div id='SubContainer' style={{ marignLeft: 'auto', marginRight: 'auto', marginTop: 10 }}>
                        <p onClick={() => window.location.href='/waitlist/status'} style={{ margin: 0, padding: 0, fontSize: 12, color: 'lightgrey', textDecorationLine: 'underline', cursor: 'pointer' }}>Already joined waitlist? Check your status here.</p>
                    </div>
                </div>

                <div id='Button' style={{ marginTop: 15, width: '95%', marginLeft: 'auto', marginRight: 'auto' }}>
                    <div id='Container' style={{ marignLeft: 'auto', marginRight: 'auto' }}>
                        <Material.Button onClick={() => handleSubmitBtn()} variant='contained' style={{ width: '100%', marginTop: 10, height: 40, background: '#4361ee', color: '#fff', borderRadius: 5 }}>
                            <div id='Row' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <div>
                                    <p style={{ margin: 0, padding: 0, fontSize: 12, color: 'lightgrey' }}>Join the waitlist.</p>
                                </div>
                            </div>
                        </Material.Button>
                    </div>
                </div>
            </CustomSmallBox >
*/


const CustomSmallBox = styled.div`
    padding: 15px 25px 30px 25px;
    border-radius: 10px;
    background-color: #2f3340;
    margin: auto auto;
    width:45%;
    margin-top: 5%;

    @media (max-width: 767px) {
        width: 80%;
    }
`;