import React, { useState, createRef } from 'react';
import { fetchAddUserToWaitlist, fetchVerifyCode } from '../../services/apiService';
import styled from 'styled-components';

const SmsCodeVerification = ({phoneNumber, email}) => {
    const [code, setCode] = useState('');
    const codeInputRefs = Array.from({ length: 6 }).map(() => createRef());

    const handleChange = (e, index) => {
        const { value } = e.target;
        if (isNaN(value)) return;
        setCode((prevCode) => {
            const newCode = [...prevCode];
            newCode[index] = value;
            return newCode.join('');
        });

        if (value && index < 5) {
            codeInputRefs[index + 1].current.focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !code[index] && index > 0) {
            codeInputRefs[index - 1].current.focus();
        }
    };

    const submitAccountCreation = async () => {
        const result = await fetchAddUserToWaitlist(email, phoneNumber);

        if (Object.keys(result).length === 0) return alert('Something went wrong. Please try again later.');
        if (!result) return alert('Something went wrong. Please try again later.');

        return window.location.href = `/waitlist/status?email=${email}`;
    }

    const handleSubmitBtn = async () => {
        if (code.length !== 6) return alert('Please enter a valid code.');
        if (phoneNumber === '') return alert('Something went wrong. Please try again later.');

        const result = await fetchVerifyCode(phoneNumber, code);
        if (!result) return alert('Something went wrong. Please try again later.');

        if (result.status === 'approved' && result.valid) {
            submitAccountCreation();
            return;
        };

        return alert('Invalid code. Please try again.');
    }

    return (
        <CustomDiv id='Container' >
            <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                {Array.from({ length: 6 }).map((_, index) => (
                    <InputBox
                        key={index}
                        inputMode="numeric"
                        maxLength={1}
                        placeholder='*'
                        value={code[index] || ''}
                        onChange={(e) => handleChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        ref={codeInputRefs[index]}
                    />
                ))}
            </div>

            <div id='Container' style={{marginTop:10}}>
                <p style={{margin:0, padding:0, fontSize:12, color:'lightgrey', textAlign:'center'}}>Enter the 6-digit code we sent to your phone.</p>
            </div>

            <div id='Submit' style={{marginTop:20}}>
                <div id='Container' style={{marignLeft:'auto', marginRight:'auto', textAlign:'center'}}>
                    <button onClick={() => handleSubmitBtn()} style={{width:'100%', height:40, borderRadius:5, border:0, background:'#3d348b', color:'#fff', fontWeight:'bold', fontSize:20, cursor:'pointer'}}>Submit</button>
                </div>
            </div>
        </CustomDiv>
    );
};

const CustomDiv = styled.div`
    @media (max-width: 767px) {
        width:95%;
    }
`

const InputBox = styled.input`
    width:50px;
    height:50px;
    border-radius:5px;
    border:0;
    text-align:center;
    margin:10px;
    font-weight:bold;
    font-size:20px;

    @media (max-width: 767px) {
        width:30px;
        height:45px;
        margin:5px;
    }
`

export default SmsCodeVerification;
