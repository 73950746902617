import react, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as Material from '@mui/material';
import { fetchAddFollowedBrands, fetchAllBrands } from '../../../services/apiService';
import { useLocation, useNavigate } from 'react-router-dom';

export const BrandScreen = ({ }) => {
    const [brands, setBrands] = useState([]);
    const [followed, setFollowed] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();

    const { accountId } = location.state || {};

    const handleSubmitBtn = async () => {
        const data = {
            userId: accountId,
            brandIds: followed
        };

        const result = await fetchAddFollowedBrands(data);
        if (result.message !== 'User followed brand') return alert('Issue following brands, please try again later.');

        window.location.href = 'https://apps.apple.com/us/app/waves-social/id1583176806';
        return navigate('/');
    }

    const handleSelectBrand = (brand) => {
        const index = followed.indexOf(brand.brandId);
        if (index >= 0) {
            const updatedFollowed = [...followed];
            updatedFollowed.splice(index, 1);
            return setFollowed(updatedFollowed);
        } else {
            return setFollowed([...followed, brand.brandId]);
        };
    };

    useEffect(() => {
        fetchAllBrands().then((brands) => {
            if (!brands) return;
            setBrands(brands.brands);
        });
    }, []);

    if (!accountId) return window.location.href = '/signup';

    return (
        <div className='App' style={{ minHeight: '100vh', height: '100%' }}>
            <div id='Nav'>
                <div id='navContainer' style={{ height: 100, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <div id='Image' style={{ marginRight: 'auto', marginLeft: 'auto', width: '90%' }} />
                </div>
            </div>

            <div id='Box' style={{ maxWidth: '80%', marginLeft: 'auto', marginRight: 'auto', marginBottom:'5%' }}>
                <Material.Box sx={{ borderRadius: 1, background: '#1a1d2e', padding: 3, paddingTop: 4, paddingBottom: 3 }}>
                    <div id='Header' style={{}}>
                        <Material.Typography variant='h4' style={{ color: '#fff', fontWeight: 600 }}>
                            Don't miss out on your favorite brands!
                        </Material.Typography>
                        <Material.Typography variant='h6' style={{ color: '#fff', fontSize: 15, fontWeight: 300, marginTop: 5 }}>
                            Follow your favorite brands to see their latest posts.
                        </Material.Typography>
                    </div>

                    <div id='Container' style={{marginTop:20}}>
                        {brands.map((brand, index) => (
                            <div onClick={() => handleSelectBrand(brand)} id='brand' key={index} style={{ background: followed.includes(brand.brandId) ? '#2196f3' : '#1E203B', borderRadius: 10, cursor: 'pointer', marginBottom:10 }}>
                                <div id='Row' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 10 }}>
                                    <div id='Image' style={{ marginRight: 10 }} >
                                        <img src={brand.brandProfileImage} style={{ height: 30, width: 30, borderRadius: 5 }} />
                                    </div>
                                    <div id='Text' style={{}} >
                                        <p className='text-link:Poppins' style={{ margin: 0, padding: 0, fontSize: 15, fontWeight: '600', color:followed.includes(brand.brandId) ? '#000':'#fff' }}>{brand.brandName}</p>
                                        <p className='text-link:Poppins' style={{ margin: 0, padding: 0, fontSize: 12, fontWeight: '300', color:followed.includes(brand.brandId) ? '#000':'grey', paddingTop:5}}>@{brand.brandName.toLowerCase()}</p>
                                    </div>
                                </div>
                                <div id='Text' style={{ marginTop:0 }}>
                                    <p className='text-link:Poppins' style={{ margin: 0, padding: 0, fontSize: 12, color:followed.includes(brand.brandId) ? '#000':'#fff', fontWeight: '300', letterSpacing:0.5, paddingLeft:10, paddingRight:10, paddingBottom:10 }}>{brand.brandDescription}</p>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div id='Submit' style={{ marginTop: 30 }}>
                        <button onClick={() => handleSubmitBtn()} style={{ width: '100%', borderRadius: 10, padding: 10, height: 40, border: 0, background: '#06d6a0', cursor: 'pointer' }}>
                            <div id='Row' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div id='Label' style={{}} >
                                    <p className='text-link:Poppins' style={{ margin: 0, padding: 0, fontSize: 15, fontWeight: '600', color:'#000' }}>Complete</p>
                                </div>
                                <div id='Image' style={{ marginLeft: 'auto' }} >
                                    <img src='https://storage.googleapis.com/waves_general_bucket/app_assets/icons/arrow-right-2.png' style={{ height: 15, width: 15 }} />
                                </div>
                            </div>
                        </button>
                    </div>
                </Material.Box>
            </div>

            <div id='Footer' style={{ height: 100 }} />
        </div>
    )
}