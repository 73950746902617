import react from 'react';
import * as Material from '@mui/material';
import { NavbarComponent } from '../common/navbar';
import { fetchAddUserToWaitlist, fetchCreateVerification, fetchWhitelistUserByEmail } from '../../services/apiService';
import styled from 'styled-components';

import React, { useState, useEffect } from 'react';

export const StatusScreen = ({ }) => {
    const [email, setEmail] = useState(null);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchWhitelistUser = async (email) => {
        await setLoading(true);
        await setEmail(email);
        const result = await fetchWhitelistUserByEmail(email);

        if (Object.keys(result).length === 0) {
            await setLoading(false);
            return window.location.href = '/';
        };
        if (!result) return await setLoading(false);

        await setData(result);
        return await setLoading(false);
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const emailParam = urlParams.get('email');
        if (emailParam) {
            fetchWhitelistUser(emailParam);
        }
    }, []);


    if (loading === true) {
        return (
            <div className='App' style={{ height: '100%', minHeight: '100vh' }}>
                <div>
                    <NavbarComponent />
                </div>
            </div>
        )
    }


    if (data) {
        return (
            <div className='App' style={{ height: '100%', minHeight: '100vh' }}>
                <div>
                    <NavbarComponent />
                </div>

                <CustomSmallBox>
                    <div id='Top' style={{}}>
                        <div id='Image' style={{ marginTop: 10, width: '100%' }}>
                            <div id='Container' style={{ marignLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
                                <img src='https://storage.googleapis.com/waves_general_bucket/waiting.png' style={{ height: 'auto', width: 'auto', maxHeight: 100, maxWidth: 100 }} />
                            </div>
                        </div>
                        <div id='Title' style={{ marginTop: 25, width: '100%' }}>
                            <div id='Container' style={{ marignLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
                                <h1 className='font-link:Poppins' style={{ color: '#fff', fontSize: 30, letterSpacing: 1, margin: 0, padding: 0 }}>You're on the Waitlist!</h1>
                                <p className='font-link:Poppins' style={{ color: '#fff', fontSize: 15, letterSpacing: 1, margin: 0, paddingTop: 5 }}>You will be notified when it's time to get started.</p>
                            </div>
                        </div>
                    </div>

                    <div id='Collection' style={{ marginTop: '5%', width: '95%', maxWidth:'95%', marginLeft: 'auto', marginRight: 'auto' }}>
                        <div id='Container' style={{ marignLeft: 'auto', marginRight: 'auto' }}>
                            <CustomHeader>{data.email}</CustomHeader>

                            <div id='Container' style={{ marginTop: 10 }}>
                                <p style={{ margin: 0, padding: 0, fontSize: 15, color: 'lightgrey', textAlign:'center' }}>Your position in line is <span style={{fontWeight:'900'}}>{data.position}</span>.</p>
                            </div>
                        </div>
                    </div>
                </CustomSmallBox>
            </div>
        );
    }

    return (
        <div className='App' style={{ height: '100%', minHeight: '100vh' }}>
            <div>
                <NavbarComponent />
            </div>

            <CustomSmallBox>
                <div id='Top' style={{}}>
                    <div id='Image' style={{ marginTop: 10, width: '100%' }}>
                        <div id='Container' style={{ marignLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
                            <img src='https://storage.googleapis.com/waves_general_bucket/waiting.png' style={{ height: 'auto', width: 'auto', maxHeight: 100, maxWidth: 100 }} />
                        </div>
                    </div>
                    <div id='Title' style={{ marginTop: 25, width: '100%' }}>
                        <div id='Container' style={{ marignLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
                            <h1 className='font-link:Poppins' style={{ color: '#fff', fontSize: 30, letterSpacing: 1, margin: 0, padding: 0 }}>What's your status?</h1>
                            <p className='font-link:Poppins' style={{ color: '#fff', fontSize: 15, letterSpacing: 1, margin: 0, paddingTop: 5 }}>Enter your email to check your status.</p>
                        </div>
                    </div>
                </div>

                <div id='Collection' style={{ marginTop: '5%', width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                    <div id='Container' style={{ marignLeft: 'auto', marginRight: 'auto' }}>
                        <p style={{ margin: 0, padding: 0, fontSize: 12, color: 'lightgrey' }}>Enter your email.</p>
                    </div>
                    <div id='Container' style={{ marignLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
                        <Material.TextField placeholder='Email' required value={email} onChange={(e) => setEmail(e.target.value)} id='email' size='small' variant='outlined' style={{ width: '100%', marginTop: 10, background: 'whitesmoke', borderRadius: 5 }} />
                    </div>
                </div>

                <div id='Submit' style={{ marginTop: 20 }}>
                    <div id='Container' style={{ marignLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
                        <button onClick={() => window.location.href = `/waitlist/status?email=${email}`} style={{ width: '100%', height: 40, borderRadius: 5, border: 0, background: '#3d348b', color: '#fff', fontWeight: 'bold', fontSize: 20, cursor: 'pointer' }}>Find My Status</button>
                    </div>
                </div>
            </CustomSmallBox>
        </div>
    );
};



const CustomSmallBox = styled.div`
    padding: 15px 25px 30px 25px;
    border-radius: 10px;
    background-color: #2f3340;
    margin: auto auto;
    width:45%;
    margin-top: 5%;

    @media (max-width: 767px) {
        width: 80%;
    }
`;

const CustomHeader = styled.h1`
    font-family: 'Poppins';
    font-size: 25px;
    color: #fff;
    margin: 0;
    padding: 0;
    letter-spacing: 1;
    text-align: center;
    color: #57cc99;
`