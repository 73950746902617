import _ from "lodash";
import {
    AgeRange,
    Bonuses,
    Gender,
    GenderTargeting,
    IAgeDemographic,
    IAudienceDemographics,
    IBrandInformation,
    ICampaignTargeting,
    IGenderDemographics,
    ILocationDemographic,
    IUserInformation,
    Importance,
    Region,
    SubmissionTypes,
    ContentTypes,
    Platform,
} from "../types/payoutTypes";

type ContentMultipliers = {
    [contentType in ContentTypes]?: {
        [submissionType in SubmissionTypes]?: {
            [platform in Platform]?: number;
        };
    };
};

export const CONTENT_MULTIPLIERS: ContentMultipliers = {
    SHORT_FORM: {
        PRODUCT_PLACEMENT: {
            INSTAGRAM: 0.2,
            TIKTOK: 0.2,
        },
        PRODUCT_FEATURE: {
            INSTAGRAM: 0.5,
            TIKTOK: 0.5,
        },
        PRODUCT_ACTION: {
            INSTAGRAM: 1,
            TIKTOK: 1,
        },
        TESTIMONIAL: {
            INSTAGRAM: 1,
            TIKTOK: 1,
        },
        UNBOXING: {
            INSTAGRAM: 0.75,
            TIKTOK: 0.75,
        },
    },
    FEED_POST: {
        PRODUCT: {
            INSTAGRAM: 0.3,
        },
        LIFESTYLE: {
            INSTAGRAM: 0.4,
        },
        PORTRAIT: {
            INSTAGRAM: 0.4,
        },
    },
    STORY_POST: {
        PRODUCT: {
            INSTAGRAM: 0.3,
        },
        LIFESTYLE: {
            INSTAGRAM: 0.4,
        },
        PORTRAIT: {
            INSTAGRAM: 0.4,
        },
    },
};

const BONUS_MULTIPLIERS = {
    PRESENTATION: 1,
    HOOK: 1,
    UNIQUENESS: 0.5,
    CONFIDENCE: 0.5,
    BRANDING: 0.5,
    AUDIO: 0.5,
    LIGHTING: 0.5,
};

const COUNTRY_WEIGHTS = {
    US: 1.3,
    AU: 1.15,
    UK: 1,
    CA: 1,
};

type ExpectedConversions = {
    [contentType in ContentTypes]?: {
        [submissionType in SubmissionTypes]?: number;
    };
};

const EXPECTED_CONVERSIONS: ExpectedConversions = {
    SHORT_FORM: {
        PRODUCT_PLACEMENT: 0.02,
        PRODUCT_FEATURE: 0.05,
        PRODUCT_ACTION: 0.1,
        TESTIMONIAL: 0.1,
        UNBOXING: 0.075,
    },
    FEED_POST: {
        PRODUCT: 0.03,
        LIFESTYLE: 0.04,
        PORTRAIT: 0.04,
    },
    STORY_POST: {
        PRODUCT: 0.015,
        LIFESTYLE: 0.02,
        PORTRAIT: 0.02,
    },
};

const LOGARITHMIC_MULTIPLIER = 0.5;
const BONUS_WEIGHT = 0.1;
const MINIMUM_BONUS_MULTIPLIER = 0.55;

const importanceScore = (importance: Importance): number => {
    switch (importance) {
        case "IMPORTANT":
            return 2;
        case "NEUTRAL":
            return 1;
        case "NOT_IMPORTANT":
            return 0;
    }
};

interface IAlignmentMultiplierWeights {
    genderImportanceWeight: number;
    ageImportanceWeight: number;
    locationImportanceWeight: number;
}

const calculateAlignmentMultiplierWeights = ({
    genderImportance,
    ageImportance,
    locationImportance,
}: {
    genderImportance: Importance;
    ageImportance: Importance;
    locationImportance: Importance;
}): IAlignmentMultiplierWeights => {
    const genderImportanceScore = importanceScore(genderImportance);
    const ageImportanceScore = importanceScore(ageImportance);
    const locationImportanceScore = importanceScore(locationImportance);

    const importanceScoresSum = genderImportanceScore + ageImportanceScore + locationImportanceScore;

    return {
        genderImportanceWeight: genderImportanceScore / importanceScoresSum,
        ageImportanceWeight: ageImportanceScore / importanceScoresSum,
        locationImportanceWeight: locationImportanceScore / importanceScoresSum,
    };
};

interface IPostInformation {
    views: number;
    likes?: number;
    comments?: number;
    shares?: number;
    contentType: ContentTypes;
    submissionType: SubmissionTypes;
    platform: Platform;
}

interface ICalculatePayoutMessage {
    campaignTargeting: ICampaignTargeting;
    creatorAudienceDemographics: IAudienceDemographics;
    brandInformation: IBrandInformation;
    userInformation: IUserInformation;
    completedBonuses: Bonuses[];
    postInformation: IPostInformation;
}

interface ILocationDemographicAlignmentWeight {
    region: Region;
    weight: number;
}

const calculateLocationDemographicsAlignmentWeights = ({
    locationDemographics,
    targetLocations,
}: {
    locationDemographics: ILocationDemographic[];
    targetLocations: Region[];
}): ILocationDemographicAlignmentWeight[] => {
    const locationDemographicAlignmentScores: ILocationDemographicAlignmentWeight[] = [];

    locationDemographics.forEach((locationDemographic) => {
        const { region, percentage } = locationDemographic;

        if (targetLocations.includes(region)) {
            locationDemographicAlignmentScores.push({
                region,
                weight: percentage * COUNTRY_WEIGHTS[region],
            });
        }
    });

    return locationDemographicAlignmentScores;
};

const calculateLocationDemographicsAlignmentScore = (
    locationDemographicAlignmentWeights: ILocationDemographicAlignmentWeight[]
): number => {
    return locationDemographicAlignmentWeights.reduce(
        (acc: number, locationDemographicAlignmentScore: ILocationDemographicAlignmentWeight) => {
            return acc + locationDemographicAlignmentScore.weight;
        },
        0
    );
};

interface IAgeDemographicAlignmentWeight {
    ageRange: AgeRange;
    weight: number;
}

const calculateAgeDemographicAlignmentWeights = ({
    ageDemographics,
    targetAges,
}: {
    ageDemographics: IAgeDemographic[];
    targetAges: AgeRange[];
}): IAgeDemographicAlignmentWeight[] => {
    const ageDemographicAlignmentScores: IAgeDemographicAlignmentWeight[] = [];

    ageDemographics.forEach((ageDemographic) => {
        const { ageRange, percentage } = ageDemographic;

        if (targetAges.includes(ageRange)) {
            ageDemographicAlignmentScores.push({
                ageRange,
                weight: percentage,
            });
        }
    });

    return ageDemographicAlignmentScores;
};

const calculateAgeDemographicAlignmentScore = (ageDemographicAlignmentWeights: IAgeDemographicAlignmentWeight[]): number => {
    return ageDemographicAlignmentWeights.reduce((acc: number, ageDemographicAlignmentScore: IAgeDemographicAlignmentWeight) => {
        return acc + ageDemographicAlignmentScore.weight;
    }, 0);
};

interface IGenderAlignmentWeights {
    maleWeight: number;
    femaleWeight: number;
}

const calculateGenderAlignmentScore = ({
    creatorGender,
    creatorGenderDemographics,
    targetGender,
}: {
    creatorGender: Gender;
    creatorGenderDemographics: IGenderDemographics;
    targetGender: GenderTargeting;
}): number => {
    const { malePercentage, femalePercentage } = creatorGenderDemographics;
    let matchingGenderWeight: number;
    let nonMatchingGenderWeight: number;

    switch (targetGender) {
        case "MALE":
        case "FEMALE":
            matchingGenderWeight = 1;
            nonMatchingGenderWeight = 0.1;
            break;
        case "MALE_LEANING":
        case "FEMALE_LEANING":
            matchingGenderWeight = 1;
            nonMatchingGenderWeight = 1 / 3;
            break;
        case "BOTH":
            if (creatorGender === "OTHER") {
                matchingGenderWeight = 0.75;
                nonMatchingGenderWeight = 0.75;
            } else {
                matchingGenderWeight = 1;
                nonMatchingGenderWeight = 0.5;
            }
            break;
    }

    const genderAlignmentWeights: IGenderAlignmentWeights = {
        maleWeight: creatorGender === "MALE" ? matchingGenderWeight : nonMatchingGenderWeight,
        femaleWeight: creatorGender === "FEMALE" ? matchingGenderWeight : nonMatchingGenderWeight,
    };

    const maleWeight = malePercentage * genderAlignmentWeights.maleWeight;
    const femaleWeight = femalePercentage * genderAlignmentWeights.femaleWeight;

    return maleWeight + femaleWeight;
};

const calculateBonusesMultiplier = (completedBonuses: Bonuses[]): number => {
    return (
        completedBonuses.reduce((acc: number, bonus: Bonuses) => {
            return acc + BONUS_MULTIPLIERS[bonus] * BONUS_WEIGHT;
        }, 0) + MINIMUM_BONUS_MULTIPLIER
    );
};

const log10 = (value: number): number => {
    return Math.log(value) / Math.LN10;
};

const calculateTotalPayout = ({
    postInformation,
    profileEngagementRate,
    alignmentMultiplier,
    bonusMultiplier,
    brandInformation,
}: {
    postInformation: IPostInformation;
    profileEngagementRate: number;
    alignmentMultiplier: number;
    bonusMultiplier: number;
    brandInformation: IBrandInformation;
}): {
    totalPayout: number;
    creatorEstimatedComission: number;
} => {
    const { views, likes, comments, shares, contentType, submissionType, platform } = postInformation;

    const { targetCPM, averageOrderValue, wavesCommission } = brandInformation;
    // averageOrderValue comes from advertiser
    // targetCPM and wavesCommission come from campaign

    let contentMultiplier = CONTENT_MULTIPLIERS[contentType]?.[submissionType]?.[platform];

    if (_.isNil(contentMultiplier)) {
        contentMultiplier = 1;
    }

    const multipliedCPM = targetCPM * contentMultiplier * bonusMultiplier;

    const alignedViews = views * alignmentMultiplier;

    let alignedViewsWithEngagement;

    if (!_.isNil(likes) && !_.isNil(comments) && !_.isNil(shares)) {
        alignedViewsWithEngagement = alignedViews - (likes + comments + shares) + (likes * 1.5 + comments * 2.5 + shares * 3.5);
    } else {
        const engagedAlignedViews = alignedViews * profileEngagementRate;

        alignedViewsWithEngagement = alignedViews - engagedAlignedViews + engagedAlignedViews * 2;
    }

    const logarithmicCPM = multipliedCPM * Math.pow(LOGARITHMIC_MULTIPLIER, log10(alignedViewsWithEngagement / 1000));

    const totalPayout = (alignedViewsWithEngagement / 1000) * logarithmicCPM;

    const expectedConversionRate = EXPECTED_CONVERSIONS[contentType]?.[submissionType] ?? 1;

    const logarithmicConversionRate = (expectedConversionRate * Math.pow(0.8, log10(alignedViewsWithEngagement / 1000))) / 100;

    const estimatedSales = alignedViews * logarithmicConversionRate * averageOrderValue;

    const roasPreCommission = estimatedSales / totalPayout;

    const salesPostCommission = estimatedSales - estimatedSales * wavesCommission;

    const roasPostCommission = salesPostCommission / totalPayout;

    const creatorRevenueShare = wavesCommission; // (wavesCommission * 2) / 3;

    const creatorEstimatedComission = estimatedSales * creatorRevenueShare;

    return { totalPayout, creatorEstimatedComission };
};

export const calculateAlignmentMultiplier = ({
    campaignTargeting,
    creatorAudienceDemographics,
    userInformation,
}: {
    campaignTargeting: ICampaignTargeting;
    creatorAudienceDemographics: IAudienceDemographics;
    userInformation: IUserInformation;
}) => {
    const { genderTargeting, ageTargeting, locationTargeting } = campaignTargeting;

    const locationDemographicsAlignmentWeights: ILocationDemographicAlignmentWeight[] = calculateLocationDemographicsAlignmentWeights({
        locationDemographics: creatorAudienceDemographics.locationDemographics,
        targetLocations: locationTargeting.targetLocations,
    });

    const locationDemographicsAlignmentScore = calculateLocationDemographicsAlignmentScore(locationDemographicsAlignmentWeights);

    // // console.log("locationDemographicsAlignmentScore", locationDemographicsAlignmentScore);

    const ageDemographicsAlignmentWeights: IAgeDemographicAlignmentWeight[] = calculateAgeDemographicAlignmentWeights({
        ageDemographics: creatorAudienceDemographics.ageDemographics,
        targetAges: ageTargeting.targetAges,
    });

    const ageDemographicsAlignmentScore = calculateAgeDemographicAlignmentScore(ageDemographicsAlignmentWeights);

    // // console.log("ageDemographicsAlignmentScore", ageDemographicsAlignmentScore);

    const genderAlignmentScore = calculateGenderAlignmentScore({
        creatorGender: userInformation.gender,
        creatorGenderDemographics: creatorAudienceDemographics.genderDemographics,
        targetGender: genderTargeting.targetGender,
    });

    // // console.log("genderAlignmentScore", genderAlignmentScore);

    const alignmentMultiplierWeights: IAlignmentMultiplierWeights = calculateAlignmentMultiplierWeights({
        genderImportance: genderTargeting.genderImportance,
        ageImportance: ageTargeting.ageImportance,
        locationImportance: locationTargeting.locationImportance,
    });

    const { genderImportanceWeight, ageImportanceWeight, locationImportanceWeight } = alignmentMultiplierWeights;

    const genderAlignmentMultiplier = Math.min(1.0, genderAlignmentScore) * genderImportanceWeight;

    // // console.log("genderAlignmentMultiplier", genderAlignmentMultiplier);

    const ageAlignmentMultiplier = Math.min(1.0, ageDemographicsAlignmentScore) * ageImportanceWeight;

    // // console.log("ageAlignmentMultiplier", ageAlignmentMultiplier);

    const locationAlignmentMultiplier = Math.min(1, locationDemographicsAlignmentScore) * locationImportanceWeight;

    // // console.log("locationAlignmentMultiplier", locationAlignmentMultiplier);

    const alignmentMultiplier = genderAlignmentMultiplier + ageAlignmentMultiplier + locationAlignmentMultiplier;

    // // console.log("alignmentMultiplier", alignmentMultiplier);

    return alignmentMultiplier;
};

export const calculatePayout = (calculatePayoutMessage: ICalculatePayoutMessage) => {
    const { campaignTargeting, creatorAudienceDemographics, brandInformation, userInformation, completedBonuses, postInformation } =
        calculatePayoutMessage;

    // // console.log("calculatePayoutMessage", JSON.stringify(calculatePayoutMessage));

    const bonusesMultiplier = Math.min(1.0, calculateBonusesMultiplier(completedBonuses));

    // // console.log("bonusesMultiplier", bonusesMultiplier);

    const alignmentMultiplier = calculateAlignmentMultiplier({
        campaignTargeting,
        creatorAudienceDemographics,
        userInformation,
    });

    // // console.log("alignmentMultiplier", alignmentMultiplier);

    const totalPayout = calculateTotalPayout({
        postInformation: postInformation,
        profileEngagementRate: userInformation.profileEngagementRate,
        alignmentMultiplier: alignmentMultiplier,
        bonusMultiplier: bonusesMultiplier,
        brandInformation: brandInformation,
    });

    return totalPayout;
};

export interface ILoggedOutCalculatePayoutMessage {
    brandInformation: IBrandInformation;
    userInformation: IUserInformation;
    completedBonuses: Bonuses[];
    postInformation: IPostInformation;
}

export const loggedOutEstimateTotalPayout = ({
    brandInformation,
    userInformation,
    completedBonuses,
    postInformation,
}: {
    brandInformation: IBrandInformation;
    userInformation: IUserInformation;
    completedBonuses: Bonuses[];
    postInformation: IPostInformation;
}) => {
    const bonusesMultiplier = Math.min(1.0, calculateBonusesMultiplier(completedBonuses));

    const alignmentMultiplier = 1.0;

    const totalPayout = calculateTotalPayout({
        postInformation: postInformation,
        profileEngagementRate: userInformation.profileEngagementRate,
        alignmentMultiplier: alignmentMultiplier,
        bonusMultiplier: bonusesMultiplier,
        brandInformation: brandInformation,
    });

    return totalPayout;
};

interface ICalculateDynamicDiscount {
    userInformation: IUserInformation;
    brandInformation: IBrandInformation;
    discountValue: number;
    alignmentMultiplier: number;
}

export const calculateDynamicDiscount = (calculateDynamicDiscount: ICalculateDynamicDiscount) => {
    const { discountValue, userInformation, brandInformation, alignmentMultiplier } = calculateDynamicDiscount;

    // console.log("alignmentMultiplier", alignmentMultiplier);

    const averageOrderValue = brandInformation.averageOrderValue;

    // console.log("averageOrderValue", averageOrderValue);

    if (_.isNil(averageOrderValue) || averageOrderValue === 0) {
        // console.log("averageOrderValue nil or 0");
        return discountValue;
    }

    const followers = userInformation.followers;

    // console.log("followers", followers);

    const engagementRate = userInformation.profileEngagementRate;

    // console.log("engagementRate", engagementRate);

    const estimatedEngagements = followers * engagementRate;

    // console.log("estimatedEngagements", estimatedEngagements);

    const estimatedAlignedFollowers = followers * alignmentMultiplier - estimatedEngagements + estimatedEngagements * 2.5;

    // console.log("estimatedAlignedFollowers", estimatedAlignedFollowers);

    const alignedThreshold = averageOrderValue * 200;

    // console.log("alignedThreshold", alignedThreshold);

    const isFree = estimatedAlignedFollowers >= alignedThreshold;

    // console.log("isFree", isFree);

    const dynamicDiscount = isFree ? 1.0 : discountValue;

    // console.log("dynamicDiscount", dynamicDiscount);

    return dynamicDiscount;
};
