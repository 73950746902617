import react, { useState, createRef, useEffect } from 'react';
import * as Material from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { fetchCreateUser, fetchVerifyCode } from '../../../services/apiService';

export const VerificationScreen = ({ }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { data } = location.state || {};

    const [code, setCode] = useState('');
    const codeInputRefs = Array.from({ length: 6 }).map(() => createRef());

    const handleChange = (e, index) => {
        const { value } = e.target;
        if (isNaN(value)) return;
        setCode((prevCode) => {
            const newCode = [...prevCode];
            newCode[index] = value;
            return newCode.join('');
        });

        if (value && index < 5) {
            codeInputRefs[index + 1].current.focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !code[index] && index > 0) {
            codeInputRefs[index - 1].current.focus();
        }
    };

    const handleSubmitCode = async () => {
        if (code.length !== 6) return alert('Please enter a valid code.');
        if (data.phone === '') return alert('Something went wrong. Please try again later.');

        const result = await fetchVerifyCode(data.phone, code);
        if (!result) return alert('Something went wrong. Please try again later.');

        if (result.status === 'approved' && result.valid) {
            const account = await fetchCreateUser(data);
            if (account.message && account.message !== 'success') return alert(account.message);
            if (!account) return alert('Something went wrong. Please try again later.');


            return navigate('/signup/interests', { state: { account:account.data } });
        };

        return alert('Invalid code. Please try again.');
    }

    if (!data) return window.location.href = '/signup';

    return (
        <div className='App' style={{ minHeight: '100vh', height: '100%' }}>
            <div id='Nav'>
                <div id='navContainer' style={{ height: 100, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <div id='Image' style={{ marginRight: 'auto', marginLeft: 'auto', width: '90%' }}>
                    </div>
                </div>
            </div>

            <div id='Box' style={{ maxWidth: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
                <Material.Box sx={{ borderRadius: 1, background: '#1a1d2e', padding: 3, paddingTop: 4, paddingBottom: 4 }}>
                    <div id='Header' style={{}}>
                        <Material.Typography variant='h4' style={{ color: '#fff', fontWeight: 600, textAlign: 'center' }}>
                            Let's verify your account.
                        </Material.Typography>
                        <Material.Typography variant='h6' style={{ color: '#fff', fontSize: 15, fontWeight: 300, marginTop: 5, textAlign: 'center' }}>
                            We have sent a verification code to {data.phone}.
                        </Material.Typography>
                    </div>

                    <CustomDiv id='Container' style={{ width: '100%', marginTop: 15 }} >
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {Array.from({ length: 6 }).map((_, index) => (
                                <InputBox
                                    key={index}
                                    inputMode="numeric"
                                    maxLength={1}
                                    placeholder='*'
                                    value={code[index] || ''}
                                    onChange={(e) => handleChange(e, index)}
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                    ref={codeInputRefs[index]}
                                />
                            ))}
                        </div>
                    </CustomDiv>

                    <div id='Container' style={{ marginTop: 10 }}>
                        <p style={{ margin: 0, padding: 0, fontSize: 12, color: 'lightgrey', textAlign: 'center' }}>Enter the 6-digit code we sent to your phone.</p>
                    </div>

                    <div id='Submit' style={{ marginTop: 25, display:'flex', justifyContent:'center', alignItems:'center' }}>
                        <button onClick={() => handleSubmitCode()} disabled={code.length !== 6} style={{ width: '50%', maxWidth:400, borderRadius: 10, padding: 10, height: 40, border: 0, background:(code.length !== 6) ? 'grey':'#06d6a0', cursor: 'pointer' }}>
                            <div id='Row' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div id='Label' style={{}} >
                                    <p className='text-link:Poppins' style={{ margin: 0, padding: 0, fontSize: 15, fontWeight: '600', color:'#000' }}>Next</p>
                                </div>
                                <div id='Image' style={{ marginLeft: 'auto' }} >
                                    <img src='https://storage.googleapis.com/waves_general_bucket/app_assets/icons/arrow-right-2.png' style={{ height: 15, width: 15 }} />
                                </div>
                            </div>
                        </button>
                    </div>

                </Material.Box>
            </div>
        </div>
    )
}

const CustomDiv = styled.div`
    @media (max-width: 767px) {
        width:95%;
    }
`;

const InputBox = styled.input`
    width:50px;
    height:50px;
    border-radius:5px;
    border:0;
    text-align:center;
    margin:10px;
    font-weight:bold;
    font-size:20px;

    @media (max-width: 767px) {
        width:25px;
        height:40px;
        margin:5px;
    }
`