import react, { useState } from 'react';
import styled from 'styled-components';
import * as Material from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchPushUserInterests } from '../../../services/apiService';

export const InterestScreen = ({ }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { account } = location.state || {};
    const options = ['Apparel', 'Fitness', 'Skincare', 'Cosmetics', 'Health & Wellness', 'Home & Garden', 'Electronics', 'Food & Beverage', 'Jewelry & Accessories', 'Toys & Games', 'Pet Supplies', 'Books & Media', 'Automotive', 'Crafts & DIY', 'Travel', 'Sports & Outdoors', 'Baby & Kids', 'Musical Intstruments', 'Office Supplies', 'Collectibles & Memorabilia', 'Other']
    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleSubmitBtn = async () => {
        const data = {
            primary: selectedOptions,
            secondary: []
        };

        const response = await fetchPushUserInterests(account.accountId, data);
        if (!response) return alert('Something went wrong. Please try again later.');

        return navigate('/signup/brands', { state: { accountId: account.accountId } });
    }

    const handleOptionPress = (option) => {
        const index = selectedOptions.indexOf(option);
        if (index >= 0) {
            // Option is already selected, remove it from the array
            const updatedOptions = [...selectedOptions];
            updatedOptions.splice(index, 1);
            setSelectedOptions(updatedOptions);
        } else {
            // Option is not selected, add it to the array
            setSelectedOptions([...selectedOptions, option]);
        }
    };

    if (!account) return window.location.href = '/signup';

    return (
        <div className='App' style={{ minHeight: '100vh', height: '100%' }}>
            <div id='Nav'>
                <div id='navContainer' style={{ height: 100, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <div id='Image' style={{ marginRight: 'auto', marginLeft: 'auto', width: '90%' }} />
                </div>
            </div>

            <div id='Box' style={{ maxWidth: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
                <Material.Box sx={{ borderRadius: 1, background: '#1a1d2e', padding: 3, paddingTop: 4, paddingBottom: 3 }}>
                    <div id='Header' style={{}}>
                        <Material.Typography variant='h4' style={{ color: '#fff', fontWeight: 600 }}>
                            What do you want to see on Waves?
                        </Material.Typography>
                        <Material.Typography variant='h6' style={{ color: '#fff', fontSize: 15, fontWeight: 300, marginTop: 5 }}>
                            Select at least 3 categories to personalize your feed. You can always change these later.
                        </Material.Typography>
                    </div>

                    <div id='Container' style={{ flexWrap: 'wrap', flexDirection: 'row', display: 'flex', marginLeft: 'auto', marginRight: 'auto', marginTop: 20 }}>
                        {options.map((option, index) => (
                            <div onClick={() => handleOptionPress(option)} id='option' key={index} style={{ background: selectedOptions.includes(option) ? '#2196f3' : '#e0e0e0', margin: 5, borderRadius: 10, cursor: 'pointer' }}>
                                <p style={{ color: '#000', padding: 0, margin: 0, fontSize: 12, padding: 6, paddingLeft: 7, paddingRight: 7 }}>
                                    {option}
                                </p>
                            </div>
                        ))}
                    </div>

                    <div id='Submit' style={{ marginTop: 30 }}>
                        <button disabled={selectedOptions.length < 3} onClick={() => handleSubmitBtn()} style={{ width: '100%', borderRadius: 10, padding: 10, height: 40, border: 0, background:selectedOptions.length < 3 ? 'grey':'#06d6a0', cursor: 'pointer' }}>
                            <div id='Row' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div id='Label' style={{}} >
                                    <p className='text-link:Poppins' style={{ margin: 0, padding: 0, fontSize: 15, fontWeight: '600', color:'#000' }}>Next ({selectedOptions.length} of 3 Selected)</p>
                                </div>
                                <div id='Image' style={{ marginLeft: 'auto' }} >
                                    <img src='https://storage.googleapis.com/waves_general_bucket/app_assets/icons/arrow-right-2.png' style={{ height: 15, width: 15 }} />
                                </div>
                            </div>
                        </button>
                    </div>
                </Material.Box>
            </div>
        </div>
    )
}