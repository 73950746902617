import react, { useState } from 'react';
import * as Material from '@mui/material';
import { AccountComponent } from '../../common/account';
import { InterestsComponent } from '../../common/interests';
import { fetchCreateUser, fetchCreateVerification, fetchPushUserInterests } from '../../../services/apiService';
import { useNavigate } from 'react-router-dom';

const initialData = { email: '', password: '', firstName: '', lastName: '', phone: '' };

export const SignupScreen = ({ }) => {
    const [data, setData] = useState(initialData);
    const [error, setError] = useState('');
    const [accountId, setAccountId] = useState(null);
    const navigate = useNavigate();

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        const updatedFormData = {
            ...data,
            phone: value
        };

        const formattedPhoneNumber = formatPhoneNumber(value);
        updatedFormData.phone = formattedPhoneNumber;
        
        if (formattedPhoneNumber.length > 10) return;

        if (formattedPhoneNumber !== '' && !isValidPhoneNumber(formattedPhoneNumber)) {
            setError('Please enter a valid phone number.');
        } else {
            setError('');
        };

        setData(updatedFormData);
    };

    const formatPhoneNumber = (phoneNumber) => {
        // Format the phone number as per your requirements
        // For example, you can remove spaces, dashes, or any other characters
        // and apply your desired formatting logic
        // Here's an example that removes all non-numeric characters
        return phoneNumber.replace(/\D/g, '');
    };

    const isValidPhoneNumber = (phoneNumber) => {
        // Perform your phone number validation logic
        // Here's an example that checks if the phone number has exactly 10 digits
        const validLength = 10;
        
        if (phoneNumber.length !== validLength) {
          return false;
        }
        
        // Add any additional validation checks if needed
        
        return /^\d{10}$/.test(phoneNumber);
    };


    const handleSubmitActBtn = async () => {

        if (!data.email.includes('@') || data.email.length < 2) {
            return alert('Invalid email!');
        };

        // if (data.phoneNumber.length !== 10) return alert('Invalid phone number!');
        if (data.password.length < 6) return alert('Password must be at least 6 characters!');
        if (data.firstName.length < 2) return alert('First name must be at least 2 characters!');
        if (data.lastName.length < 2) return alert('Last name must be at least 2 characters!');

        //await setStage('interests');

        //const response = await fetchCreateUser(data);
        const response = await fetchCreateVerification(Number(data.phone));
        if (response.hasOwnProperty('error') && response.error.message.includes('Too many requests')) return alert('You have exceeded the maximum number of verification attempts. Please try again later.');
        if (response.hasOwnProperty('error') && response.error.message.includes('Invalid parameter `To`')) return alert('Please enter a valid phone number.');
        if (Object.keys(response).length === 0) return alert('Something went wrong. Please try again later.');

        return navigate('/signup/verification', { state: { data} });
    };


    return (
        <div className='App' style={{ minHeight: '100vh', height: '100%' }}>
            <div id='Nav'>
                <div id='navContainer' style={{ height: 100, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <div id='Image' style={{ marginRight: 'auto', marginLeft: 'auto', width: '90%' }}>
                    </div>
                </div>
            </div>

            <div id='Box' style={{ width: 'auto', maxWidth: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
                <Material.Box sx={{ borderRadius: 1, background: '#1a1d2e', padding: 3, paddingTop: 4, paddingBottom: 4 }}>
                    <div id='Header' style={{}}>
                        <div id='Image' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src='https://storage.googleapis.com/waves_general_bucket/image04.png' style={{ height: 'auto', width: 'auto', maxHeight: '40%', maxWidth: '40%', borderRadius: '50%', border: '5px solid #fff' }} />
                        </div>
                    </div>
                    <div id='Body' style={{ marginTop: '10%' }}>
                        <div id='Container' style={{}}>
                            <div id='Label' style={{}}>
                                <h1 style={{ margin: 0, padding: 0, color: '#fff' }}>Create Account.</h1>
                                <p style={{ margin: 0, padding: 0, color: '#fff', fontSize: 15, paddingTop: 10 }}>Join the Waves community today.</p>
                            </div>

                            <div id='Form' style={{ marginTop: 20 }}>
                                <div id='Name' style={{}}>
                                    <div id='Row' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <div id='First' style={{ marginRight: 10, width: '100%' }}>
                                            <div id='Label' style={{}}>
                                                <p className='text-link:Poppins' style={{ margin: 0, padding: 0, color: '#fff', fontWeight: '500' }}>First Name</p>
                                            </div>
                                            <div id='Input' style={{ marginTop: 15 }}>
                                                <Material.TextField placeholder='First' size='small' value={data.firstName} onChange={(e) => setData(prevState => ({ ...prevState, firstName: e.target.value }))} sx={{ background: '#fff', border: 0, borderRadius: 1, width: '100%' }} />
                                            </div>
                                        </div>

                                        <div id='Last' style={{ marginLeft: 10, width: '100%' }}>
                                            <div id='Label' style={{}}>
                                                <p className='text-link:Poppins' style={{ margin: 0, padding: 0, color: '#fff', fontWeight: '500' }}>Last Name</p>
                                            </div>
                                            <div id='Input' style={{ marginTop: 15 }}>
                                                <Material.TextField placeholder='Last' size='small' value={data.lastName} onChange={(e) => setData(prevState => ({ ...prevState, lastName: e.target.value }))} sx={{ background: '#fff', border: 0, borderRadius: 1, width: '100%' }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id='Contact' style={{ marginTop: 20 }}>
                                    <div id='Row' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <div id='Phone' style={{ marginRight: 10, width: '100%' }}>
                                            <div id='Label'>
                                                <p className='text-link:Poppins' style={{ margin: 0, padding: 0, color: '#fff', fontWeight: '500' }}>Phone Number</p>
                                            </div>
                                            <div id='Input' style={{ marginTop: 15 }}>
                                                <Material.TextField placeholder='Phone' size='small' value={data.phone} onChange={handleInputChange} sx={{ background: '#fff', border: 0, borderRadius: 1, width: '100%' }} />
                                                {error && <p style={{margin:0, padding:0, color:'red', paddingTop:5, position:'absolute', fontSize:10}}>{error}</p>}
                                            </div>
                                        </div>
                                        <div id='Email' style={{ marginLeft: 10, width: '100%' }}>
                                            <div id='Label'>
                                                <p className='text-link:Poppins' style={{ margin: 0, padding: 0, color: '#fff', fontWeight: '500' }}>Email</p>
                                            </div>
                                            <div id='Input' style={{ marginTop: 15 }}>
                                                <Material.TextField placeholder='Email' size='small' value={data.email} onChange={(e) => setData(prevState => ({ ...prevState, email: e.target.value }))} sx={{ background: '#fff', border: 0, borderRadius: 1, width: '100%' }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id='Password' style={{ marginTop: error ? 35:20 }}>
                                    <div id='Label'>
                                        <p className='text-link:Poppins' style={{ margin: 0, padding: 0, color: '#fff', fontWeight: '500' }}>Password</p>
                                    </div>
                                    <div id='Input' style={{ marginTop: 15 }}>
                                        <Material.TextField type='password' placeholder='Password' size='small' value={data.password} onChange={(e) => setData(prevState => ({ ...prevState, password: e.target.value }))} sx={{ background: '#fff', border: 0, borderRadius: 1, width: '100%' }} />
                                    </div>
                                </div>

                                <div id='Submit' style={{ marginTop: 30 }}>
                                    <button onClick={() => handleSubmitActBtn()} disabled={data.email === '' || data.firstName === '' || data.lastName === '' || data.password === '' || data.phone === ''} style={{ width: '100%', borderRadius: 10, padding: 10, height: 40, border: 0, background:(data.email === '' || data.firstName === '' || data.lastName === '' || data.password === '' || data.phone === '') ? 'grey':'#06d6a0', cursor: 'pointer' }}>
                                        <div id='Row' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <div id='Label' style={{}} >
                                                <p className='text-link:Poppins' style={{ margin: 0, padding: 0, fontSize: 15, fontWeight: '600', color:'#000' }}>Next</p>
                                            </div>
                                            <div id='Image' style={{ marginLeft: 'auto' }} >
                                                <img src='https://storage.googleapis.com/waves_general_bucket/app_assets/icons/arrow-right-2.png' style={{ height: 15, width: 15 }} />
                                            </div>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Material.Box>
            </div>

            <div id='Footer' style={{ height: 100 }} />
        </div>
    )
}