import react from 'react';

export const NavbarComponent = ({}) => {
    return (
        <div id='navContainer' style={{height:100, justifyContent:'center', alignItems:'center', display:'flex'}}>
            <div id='Image' style={{marginRight:'auto', marginLeft:'auto', width:'90%'}}>
                <img src='https://storage.googleapis.com/waves_general_bucket/WAVES_NEW.png' style={{height:50, width:50}} />
            </div>
        </div>
    )
}