import React, { useRef, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

export const MediaCarousel = ({ videos }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const videoRef = useRef(null);
    const isPlayingRef = useRef(false);

    const handleSlideChange = (index) => {
        setActiveIndex(index);
        pauseVideo();
    };

    const handleVideoEnded = () => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % videos.length);
    };

    const toggleVideoPlayback = () => {
        if (videoRef.current) {
            if (isPlayingRef.current) {
                pauseVideo();
            } else {
                playVideo();
            }
        }
    };

    const playVideo = () => {
        if (videoRef.current) {
            videoRef.current.play();
            isPlayingRef.current = true;
        }
    };

    const pauseVideo = () => {
        if (videoRef.current) {
            videoRef.current.pause();
            isPlayingRef.current = false;
        }
    };

    return (
        <Carousel
            selectedItem={activeIndex}
            onChange={handleSlideChange}
            emulateTouch
            showThumbs={false}
            showArrows={false}
            showStatus={false}
            renderIndicator={(onClickHandler, isSelected, index, label) => {
                // if (isSelected) {
                    return (
                        <div
                            style={{
                                width: 10,
                                height: 10,
                                borderRadius: "50%",
                                backgroundColor: "#fff",
                                opacity: isSelected ? 1 : 0.5,
                                display: "inline-block",
                                marginRight: "8px",
                                marginLeft: "8px",
                                // marginTop: "20px",
                                cursor: "pointer",
                                // position: "absolute"
                            }}
                            aria-label={`Selected: ${label} ${index + 1}`}
                            title={`Selected: ${label} ${index + 1}`}
                            onClick={onClickHandler}
                        />
                    );
                // } else {

                // }
            }}
        >
            {videos.map((video, index) => (
                <div key={index}>
                    <video
                        ref={index === activeIndex ? videoRef : null}
                        src={video}
                        autoPlay={index === activeIndex ? true : false}
                        controls={{ play: true, pause: true }}
                        onEnded={handleVideoEnded}
                        style={{
                            height: "auto",
                            width: "auto",
                            maxWidth: "100%",
                            maxHeight: "90%",
                            objectFit: "contain",
                            borderRadius: 10,
                        }}
                    />
                </div>
            ))}
        </Carousel>
    );
};
