import { api_Config } from "./config";

export const handleFetchCollectionProducts = async (campaign) => {
    const collectionId = campaign.campaign_product?.[0]?.basic?.collection_id;

    console.log("collectionId", collectionId);

    const result = await aws_getCollectionProductsById(collectionId, campaign.campaign_advertiser ?? "");

    console.log("result", result);
    if (!result || !result.success) {
        return [];
    }

    const products = result.products?.products?.edges?.map((edge) => edge.node) ?? [];

    console.log("products", products);
    // const offerWithProducts = { ...campaign, collectionProducts: products };

    return products;
};

export const aws_getCollectionProductsById = async (collectionId, advertiserId) => {
    const res = await fetch(`${api_Config.awsApiUri}/shopify/collection/${advertiserId}/${collectionId}`, {
        method: "GET",
    });

    return await res.json();
};

export const fetchCampaignById = async (id) => {
    try {
        const response = await fetch(`${api_Config.awsApiUri}/offer/${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        return await response.json();
    } catch (e) {
        return;
    }
};

export const fetchCreateUser = async (object) => {
    try {
        const response = await fetch(`${api_Config.mainApiUri}/api/user/new`, {
            method: "POST",
            body: JSON.stringify(object),
            headers: {
                "Content-Type": "application/json",
            },
        });

        return await response.json();
    } catch (e) {
        return;
    }
};

export const fetchAddUserToWaitlist = async (email, phone) => {
    try {
        const response = await fetch(`${api_Config.mainApiUri}/api/waitlist/add`, {
            method: "POST",
            body: JSON.stringify({ email: email, phone: phone }),
            headers: {
                "Content-Type": "application/json",
            },
        });

        return await response.json();
    } catch (e) {
        return;
    }
};

export const fetchCreateVerification = async (phoneNumber) => {
    try {
        const response = await fetch(`${api_Config.mainApiUri}/api/waitlist/send`, {
            method: "POST",
            body: JSON.stringify({ phoneNumber: phoneNumber }),
            headers: {
                "Content-Type": "application/json",
            },
        });

        return await response.json();
    } catch (e) {
        return;
    }
};

export const fetchVerifyCode = async (phoneNumber, code) => {
    try {
        const response = await fetch(`${api_Config.mainApiUri}/api/waitlist/verify`, {
            method: "POST",
            body: JSON.stringify({ phoneNumber: phoneNumber, code: code }),
            headers: {
                "Content-Type": "application/json",
            },
        });

        return await response.json();
    } catch (e) {
        return;
    }
};

export const fetchWhitelistUserByEmail = async (email) => {
    try {
        const response = await fetch(`${api_Config.mainApiUri}/api/waitlist/status?email=${email}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        return await response.json();
    } catch (e) {
        return;
    }
};

export const fetchAllBrands = async () => {
    const response = await fetch(`${api_Config.mainApiUri}/api/brands/all`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
    });

    return await response.json();
};

export const fetchAddFollowedBrands = async (data) => {
    const response = await fetch(`${api_Config.mainApiUri}/api/brands/follow`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-Type": "application/json" },
    });

    return await response.json();
};

export const fetchPushUserInterests = async (id, interests) => {
    try {
        const response = await fetch(`${api_Config.mainApiUri}/api/user/interests/${id}`, {
            method: "POST",
            body: JSON.stringify({ interests: interests }),
            headers: {
                "Content-Type": "application/json",
            },
        });

        return await response.json();
    } catch (e) {
        return;
    }
};
