import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { BaseScreen } from "./components/screens/base";
import { HomeScreen } from "./components/screens/home";
import { SignupScreen } from "./components/screens/signup/signup";
import { WaseScreen } from "./components/screens/wase";
import { StatusScreen } from "./components/screens/status";
import { VerificationScreen } from "./components/screens/signup/verification";
import { InterestScreen } from "./components/screens/signup/interests";
import { BrandScreen } from "./components/screens/signup/brands";
import React from "react";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route index element={<HomeScreen />} />
                <Route path="/waitlist/status" element={<StatusScreen />} />
                <Route path="/signup" element={<SignupScreen />} />
                <Route path="/signup/verification" element={<VerificationScreen />} />
                <Route path="/signup/interests" element={<InterestScreen />} />
                <Route path="/signup/brands" element={<BrandScreen />} />
                {/* <Route path="/offer/:id" element={<BaseScreen />} /> */}
                <Route path="/offer/:id" element={<WaseScreen />} />
                <Route path="/*" element={<HomeScreen />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
