import React, { useEffect, useState } from "react";
import { NavbarComponent } from "../common/navbar";
import styled, { keyframes } from "styled-components";
import * as Material from "@mui/material";
import { MediaCarousel } from "../common/carousel";
import { useParams } from "react-router-dom";
import { fetchCampaignById, handleFetchCollectionProducts } from "../../services/apiService";
import AppMockups from "../../assets/app-mockups.png";
import { hsbToRgb } from "../../utils/colorUtils";
import { loggedOutEstimateTotalPayout } from "../../utils/payoutUtils";
import { useWindowSize } from "@react-hook/window-size";
import { SocialIcon } from "react-social-icons/component";
import "react-social-icons/instagram";
import "react-social-icons/tiktok";

const Slider = ({ campaign }) => {
    const [sliderValue, setSliderValue] = useState(250000);

    const newPayoutEstimate = () => {
        const calculatePayoutMessage = {
            brandInformation: {
                targetCPM: campaign.campaign_payout?.target_cpm ?? 0,
                averageOrderValue: campaign.advertiser?.brandInformation?.averageOrderValue ?? 0,
                wavesCommission: campaign.campaign_payout?.waves_commission ?? 0,
            },
            userInformation: {
                gender: "MALE", // not used in calculation
                followers: 25000,
                profileEngagementRate: 0.1,
            },
            completedBonuses: ["PRESENTATION", "HOOK", "UNIQUENESS", "CONFIDENCE", "BRANDING", "AUDIO", "LIGHTING"],
            postInformation: {
                views: sliderValue,
                contentType: "SHORT_FORM",
                submissionType: "TESTIMONIAL",
                platform: "INSTAGRAM",
            },
        };

        return loggedOutEstimateTotalPayout(calculatePayoutMessage);
    };

    function abbreviateNumber(num, decimals) {
        if (num === 0) return "0";

        let scale = Math.floor(Math.log10(Math.abs(num)) / 3);
        let suffix = ["", "K", "M", "B", "T"][scale];
        let scalePower = Math.pow(10, scale * 3);
        let scaled = num / scalePower;
        let formatted = scaled.toFixed(decimals);

        if (formatted.endsWith(".0")) {
            formatted = formatted.substring(0, formatted.length - 2);
        }

        return formatted + suffix;
    }

    const formatNumberAsPercent = (value, decimalPlaces = 1) => {
        return (value * 100).toFixed(decimalPlaces) + "%";
    };

    return (
        <div
            style={{
                width: "100%",
                marginTop: 20,
            }}
            className="items-center flex flex-col"
        >
            <div className="flex flex-row">
                <div className="text-center text-white text-xl font-bold">
                    Earn up to{" "}
                    <span className="text-[#1A79FF] ">
                        $
                        {(newPayoutEstimate().totalPayout + newPayoutEstimate().creatorEstimatedComission).toLocaleString(undefined, {
                            maximumFractionDigits: 0,
                        })}
                    </span>{" "}
                    for
                </div>
            </div>
            <div className="text-center text-white text-xl font-bold">
                <span className="text-[#1A79FF] font-bold">{abbreviateNumber(sliderValue, 1)}</span> Views
            </div>
            <div
                className="mt-4 mb-2"
                style={{
                    width: "80%",
                }}
            >
                <input
                    style={{ width: "100%" }}
                    type="range"
                    min={1000}
                    max={1000000}
                    value={sliderValue}
                    className="slider"
                    id="myRange"
                    onChange={() => {
                        setSliderValue(document.getElementById("myRange").value);
                    }}
                />
            </div>
            <div className="flex flex-row gap-8">
                <div className="text-white flex flex-col items-center text-base font-medium">
                    <div>Performance</div>
                    <div>
                        $
                        {newPayoutEstimate().totalPayout.toLocaleString(undefined, {
                            maximumFractionDigits: 0,
                        })}
                    </div>
                </div>
                <div className="text-white flex flex-col items-center text-base font-medium">
                    <div>Est. Commission</div>
                    <div>
                        $
                        {newPayoutEstimate().creatorEstimatedComission.toLocaleString(undefined, {
                            maximumFractionDigits: 0,
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export const WaseScreen = ({}) => {
    let { id } = useParams();

    const [campaign, setCampaign] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const [rendering, setRendering] = useState(true);

    const [products, setProducts] = useState([]);

    const [width, height] = useWindowSize();

    const getProductPrices = (offer, product) => {
        let campaignDiscount = offer.campaign_product?.[0]?.basic?.discount_value ?? 0;

        if (campaignDiscount > 1) {
            campaignDiscount = campaignDiscount / 100;
        }

        const price = parseFloat(product.priceRangeV2?.maxVariantPrice?.amount ?? "0");

        const discountedPrice = price * (1 - campaignDiscount);

        const priceString = `$${price.toFixed(0)}`;
        const discountedPriceString = discountedPrice === 0 ? "Free" : `$${discountedPrice.toFixed(0)}`;

        return {
            priceString,
            discountedPriceString,
        };
    };

    const tempText =
        "Tria Beauty is a brand that sells skincare and hair removal devices for at-home use. Their products use innovative technology to provide effective and long-lasting results. They offer a range of devices, including laser hair removal devices, anti-aging devices, and acne treatment devices.";

    const loadCampaign = async () => {
        if (!id) return;

        const result = await fetchCampaignById(id);
        if (!result || !result.success) return;

        console.log(result.offer);
        setCampaign(result.offer);

        const fetchProducts = await handleFetchCollectionProducts(result.offer);
        if (!fetchProducts) return;
        setProducts(fetchProducts);
    };

    useEffect(() => {
        loadCampaign().finally(() => setRendering(false));
    }, []);

    const productCardSize = width < 500 ? width / 4 : width / 5;

    return (
        <div className="App" style={{ height: "100%", minHeight: "100vh" }}>
            {/* <div>
                <NavbarComponent />
            </div> */}

            <div id="container" style={{ paddingTop: 40 }}>
                <CustomSmallBox id="box" style={{ width: width < 500 ? "90%" : "70%" }}>
                    <div style={{ width: "100%", display: "flex", marginTop: 20 }}>
                        <img
                            src="https://storage.googleapis.com/waves_general_bucket/WAVES_NEW.png"
                            style={{ height: 100, width: 100, marginLeft: "auto", marginRight: "auto" }}
                        />
                    </div>

                    <div id="box1" className="mt-6">
                        <AnimatedGradientText className="font-link:Poppins">
                            Become a Paid Partner with {campaign?.advertiser?.advertiserName}
                        </AnimatedGradientText>
                    </div>
                    <div id="sub" style={{ marginTop: 20, marginBottom: 10 }}>
                        {/* <AnimatedGradientText2> */}
                        <p>
                            Waves lets you team up with top brands, enjoy huge discounts, and earn money by sharing your experiences. Turn
                            shopping into a profitable passion with Waves.
                        </p>
                        {/* </AnimatedGradientText2> */}
                    </div>
                    {/* <div id="box2" style={{ marginTop: "10%" }}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img
                                id="circle-image"
                                src="https://storage.googleapis.com/waves_general_bucket/app_assets/icons/downArrow.png"
                            />
                        </div>
                    </div> */}
                    <div id="button" style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "10%" }}>
                        <button
                            id="animated-button"
                            onClick={() => {
                                window.open("https://apps.apple.com/us/app/waves-connect-with-brands/id1671042944", "_blank");
                            }}
                        >
                            <div id="" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <div id="text" style={{ marginRight: 10 }}>
                                    <p
                                        className="font-link:Poppins"
                                        style={{
                                            margin: 0,
                                            padding: 0,
                                            color: "white",
                                            fontWeight: "700",
                                            fontSize: 12,
                                            letterSpacing: "1px",
                                            opacity: 1,
                                        }}
                                    >
                                        DOWNLOAD NOW
                                    </p>
                                </div>
                                <div id="image" style={{ marginLeft: 10 }}>
                                    <img
                                        src="https://storage.googleapis.com/waves_general_bucket/app_assets/icons/next-2.png"
                                        style={{ width: 15, height: 15, margin: 0, padding: 0 }}
                                    />
                                </div>
                            </div>
                        </button>
                    </div>
                </CustomSmallBox>

                <div id="line" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <DottedLine />
                </div>

                <CustomBox id="box2" style={{ width: width < 500 ? "90%" : "70%" }}>
                    {/* <Material.Grid container sx={{}}>
                        <Material.Grid item xs={12} sm={6} sx={{ padding: "5%", display: "flex", alignItems: "center" }}> */}
                    <div id="box1" style={{}} className="my-6">
                        {/* <div id="box1label" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <p
                                className="text-link:Poppins"
                                style={{
                                    margin: 0,
                                    padding: 0,
                                    color: "#a2d2ff",
                                    fontWeight: "600",
                                    letterSpacing: "1px",
                                    fontSize: 12,
                                }}
                            >
                                STEP 1
                            </p>
                        </div> */}
                        <div id="box1text" style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 20 }}>
                            <AnimatedGradientText>Get Premium Products at Huge Discounts</AnimatedGradientText>
                        </div>
                        <div id="sub" style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 20 }}>
                            <p>
                                With Waves, enjoy exclusive deals on {campaign?.advertiser?.advertiserName}'s top-quality products. As a
                                brand ambassador, sharing your experiences on social media help {campaign?.advertiser?.advertiserName} grow.
                                We believe that's worth rewarding.
                            </p>
                        </div>
                        {/* <div id="button" style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "10%" }}>
                                <button id="animated-button" onClick={() => (window.location.href = "/signup")}>
                                    <div id="row" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <div id="text" style={{ marginRight: 10 }}>
                                            <p
                                                className="font-link:Poppins"
                                                style={{
                                                    margin: 0,
                                                    padding: 0,
                                                    color: "lightgrey",
                                                    fontWeight: "700",
                                                    fontSize: 12,
                                                    letterSpacing: "1px",
                                                }}
                                            >
                                                DOWNLOAD NOW
                                            </p>
                                        </div>
                                        <div id="image" style={{ marginLeft: 10 }}>
                                            <img
                                                src="https://storage.googleapis.com/waves_general_bucket/app_assets/icons/next-2.png"
                                                style={{ width: 15, height: 15, margin: 0, padding: 0 }}
                                            />
                                        </div>
                                    </div>
                                </button>
                            </div> */}
                        {/* <div id="footer" style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 20 }}>
                                    <p
                                        className="text-link:Poppins"
                                        style={{ margin: 0, padding: 0, color: "#ff006e", fontSize: 15, fontWeight: "400" }}
                                    >
                                        Only <span style={{ fontWeight: "800" }}>100</span> spots remaining.
                                    </p>
                                </div> */}
                    </div>
                    {/* </Material.Grid> */}

                    <div
                        style={{
                            width: "100%",
                            overflowX: "scroll",
                            display: "flex",
                            flexDirection: "row",
                            gap: 8,
                            marginTop: 20,
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            // open https://apps.apple.com/us/app/waves-connect-with-brands/id1671042944
                            window.open("https://apps.apple.com/us/app/waves-connect-with-brands/id1671042944", "_blank");
                        }}
                    >
                        {products?.map((coll_product) => {
                            const productPrices = getProductPrices(campaign, coll_product);

                            return (
                                <div
                                    style={{
                                        flexShrink: 0,
                                        display: "inline-block",
                                        width: productCardSize,
                                    }}
                                    onClick={() => {
                                        window.open("https://apps.apple.com/us/app/waves-connect-with-brands/id1671042944", "_blank");
                                    }}
                                >
                                    {/* <div
                                            style={{
                                                height: width / 4,
                                                paddingBottom: "100%",
                                                position: "relative",
                                            }}
                                        > */}
                                    <img
                                        src={coll_product.featuredImage?.url}
                                        className="object-cover"
                                        style={{
                                            width: productCardSize,
                                            height: productCardSize,
                                        }}
                                    />
                                    {/* </div> */}
                                    <p style={{}} className="line-clamp-1">
                                        {coll_product.title}
                                    </p>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            gap: 4,
                                            justifyContent: "center",
                                        }}
                                    >
                                        <p
                                            style={{
                                                color: "white",
                                                fontWeight: "500",
                                                fontSize: 13,
                                                lineHeight: "16px",
                                            }}
                                        >
                                            {productPrices.discountedPriceString}
                                        </p>
                                        <p
                                            style={{
                                                color: hsbToRgb(240, 10, 65),
                                                textDecorationLine: "line-through",
                                                fontSize: 13,
                                                lineHeight: "16px",
                                            }}
                                        >
                                            {productPrices.priceString}
                                        </p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    {/* </Material.Grid>
                    </Material.Grid> */}
                </CustomBox>

                <div id="line" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <DottedLine />
                </div>

                <CustomBox id="box3" style={{ width: width < 500 ? "90%" : "70%" }}>
                    <Material.Grid container sx={{}}>
                        <Material.Grid item xs={12} sm={6} sx={{ padding: "5%", display: "flex", alignItems: "center" }}>
                            <div id="box1" style={{}}>
                                {/* <div id="box1label" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <p
                                        className="text-link:Poppins"
                                        style={{
                                            margin: 0,
                                            padding: 0,
                                            color: "#a2d2ff",
                                            fontWeight: "600",
                                            letterSpacing: "1px",
                                            fontSize: 12,
                                        }}
                                    >
                                        STEP 2
                                    </p>
                                </div> */}
                                <div
                                    id="box1text"
                                    style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 15 }}
                                >
                                    <AnimatedGradientText>Share, Engage, and Inspire</AnimatedGradientText>
                                </div>
                                {/* <div
                                    id="subHeader"
                                    style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 15 }}
                                >
                                    <p>
                                        <span style={{ fontWeight: "800" }}>For you →</span>
                                    </p>
                                </div> */}
                                <div id="sub" style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 15 }}>
                                    {/* <p>
                                        Get {campaign?.campaign_product?.[0]?.basic?.discount_type === "DOLLAR" ? "$" : ""}
                                        {parseFloat(campaign?.campaign_product?.[0]?.basic?.discount_value ?? 0).toFixed(0)}
                                        {campaign?.campaign_product?.[0]?.basic?.discount_type === "PERCENTAGE" ? "%" : ""} off any product
                                        of{" "}
                                        <span style={{ fontWeight: "800" }}>
                                            {campaign?.campaign_product?.[0]?.basic?.collection_title}
                                        </span>{" "}
                                        collection.
                                    </p> */}
                                    <p>
                                        After enjoying your deals, share your experience on social media. Don’t worry, it's not as hard as
                                        it sounds! Check out these successful promotions from other Waves ambassadors.
                                    </p>
                                </div>
                                <div
                                    id="button"
                                    style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "10%" }}
                                >
                                    {/* <button
                                        id="animated-button"
                                        onClick={() => window.open(campaign?.campaign_product?.[0]?.basic?.collection_uri, "_blank")}
                                    >
                                        <div id="row" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <div id="text" style={{ marginRight: 10 }}>
                                                <p
                                                    className="font-link:Poppins"
                                                    style={{
                                                        margin: 0,
                                                        padding: 0,
                                                        color: "lightgrey",
                                                        fontWeight: "700",
                                                        fontSize: 12,
                                                        letterSpacing: "1px",
                                                    }}
                                                >
                                                    VIEW COLLECTION
                                                </p>
                                            </div>
                                            <div id="image" style={{ marginLeft: 10 }}>
                                                <img
                                                    src="https://storage.googleapis.com/waves_general_bucket/app_assets/icons/next-2.png"
                                                    style={{ width: 15, height: 15, margin: 0, padding: 0 }}
                                                />
                                            </div>
                                        </div>
                                    </button> */}
                                </div>
                            </div>
                        </Material.Grid>
                        <Material.Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{ padding: "5%", display: "flex", justifyContent: "center", alignItems: "center" }}
                        >
                            <div id="box2" style={{}}>
                                <MediaCarousel
                                    videos={[
                                        "https://storage.googleapis.com/waves_campaign_bucket/xsrh3_advertiser_07936j1g_317!_h4h3bcf278j6%26/Primary/tiktok_7167797732982197546_.mp4",

                                        "https://storage.googleapis.com/waves_campaign_bucket/xsrh3_advertiser_07936j1g_317!_h4h3bcf278j6%26/Secondary/tiktok_7167797732982197546_.mp4",
                                    ]}
                                />
                            </div>
                        </Material.Grid>
                    </Material.Grid>
                </CustomBox>

                <div id="line" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <DottedLine />
                </div>

                <CustomBox id="box3" style={{ width: width < 500 ? "90%" : "70%" }}>
                    {/* <Material.Grid container sx={{}}> */}
                    <Material.Grid item xs={12} sm={6} sx={{ padding: "5%", display: "flex", alignItems: "center" }}>
                        <div id="box1" style={{}}>
                            {/* <div id="box1label" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <p
                                    className="text-link:Poppins"
                                    style={{
                                        margin: 0,
                                        padding: 0,
                                        color: "#a2d2ff",
                                        fontWeight: "600",
                                        letterSpacing: "1px",
                                        fontSize: 12,
                                    }}
                                >
                                    STEP 3
                                </p>
                            </div> */}
                            <div id="box1text" style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 15 }}>
                                <AnimatedGradientText>Profit from Your Online Presence</AnimatedGradientText>
                            </div>
                            <div id="subHeader" style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 15 }}>
                                {/* <p>
                                        <span style={{ color: "#fff", fontWeight: "600" }}>Earn up to</span>{" "}
                                        <span style={{ color: "#06d6a0", fontWeight: "600" }}>$2,000</span> by participating in{" "}
                                        <span style={{ color: "#fff", fontWeight: "600", textDecorationLine: "underline" }}>
                                            {campaign?.campaign_name}
                                        </span>
                                    </p> */}
                                <p>
                                    Waves makes your influence count. Earn based on your post's views and interactions. Additionally, profit
                                    from sales made through your affiliate links. Find our your earning potential with our payout estimator
                                    below.
                                </p>
                            </div>
                            {/* <div id="sub" style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 15 }}>
                                    <p>Simply tag the brand and start getting paid based on the post's views and engagement.</p>
                                </div> */}
                            {campaign && <Slider campaign={campaign} />}
                        </div>
                        {/* </Material.Grid> */}
                        {/* <Material.Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{ padding: "5%", display: "flex", justifyContent: "center", alignItems: "center" }}
                        >
                            <div id="box2" style={{}}>
                                <MediaCarousel
                                    videos={campaign?.campaign_media.map((media) => media.uri)} />
                            </div>

                        </Material.Grid> */}
                    </Material.Grid>
                </CustomBox>

                <div id="line" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <DottedLine />
                </div>

                <CustomSmallBox style={{ width: width < 500 ? "90%" : "70%" }}>
                    {/* <div id="box1label" style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 15 }}>
                        <p
                            className="text-link:Poppins"
                            style={{ margin: 0, padding: 0, color: "#a2d2ff", fontWeight: "600", letterSpacing: "1px", fontSize: 12 }}
                        >
                            GET STARTED
                        </p>
                    </div> */}
                    <div id="box1">
                        <AnimatedGradientText className="mt-5 font-link:Poppins">Join Waves and Start Earning</AnimatedGradientText>
                    </div>
                    <div id="sub" style={{ marginTop: 5, marginBottom: 10 }}>
                        <p>
                            Ready to monetize your passion? Dive into the Waves app and team up with {campaign?.advertiser?.advertiserName}{" "}
                            and other top brands today. With Waves, you can start partnering with brands you love instantly. It's that easy.
                        </p>
                    </div>

                    <div id="button" style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "10%" }}>
                        <button
                            id="animated-button"
                            onClick={() => {
                                window.open("https://apps.apple.com/us/app/waves-connect-with-brands/id1671042944", "_blank");
                            }}
                        >
                            <div id="row" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <div id="text" style={{ marginRight: 10 }}>
                                    <p
                                        className="font-link:Poppins"
                                        style={{
                                            margin: 0,
                                            padding: 0,
                                            color: "white",
                                            fontWeight: "700",
                                            fontSize: 12,
                                            letterSpacing: "1px",
                                            opacity: 1,
                                        }}
                                    >
                                        DOWNLOAD NOW
                                    </p>
                                </div>
                                <div id="image" style={{ marginLeft: 10 }}>
                                    <img
                                        src="https://storage.googleapis.com/waves_general_bucket/app_assets/icons/next-2.png"
                                        style={{ width: 15, height: 15, margin: 0, padding: 0 }}
                                    />
                                </div>
                            </div>
                        </button>
                    </div>

                    <div className="flex flex-row gap-2 opacity-100 justify-center items-center mt-4">
                        <SocialIcon
                            fallback="instagram"
                            url="www.instagram.com"
                            bgColor="transparent"
                            fgColor="white"
                            target="_blank"
                            className="opacity-100"
                            href="https://instagram.com/join.waves"
                        />

                        <SocialIcon
                            fallback="tiktok"
                            url="www.tiktok.com"
                            bgColor="transparent"
                            fgColor="white"
                            target="_blank"
                            className="opacity-100"
                            href="https://www.tiktok.com/@join.waves"
                            style={{ width: 44, height: 44 }}
                        />
                    </div>
                </CustomSmallBox>
            </div>
            <div id="footer" style={{ height: 100, width: "100%", top: 0, marginTop: "20%" }} />
        </div>
    );
};

const CustomSmallBox = styled.div`
    padding: 10px 25px 30px 25px;
    border-radius: 16px;
    background-color: #30333f;
    margin: auto auto;
`;

const CustomBox = styled.div`
    padding: 10px 25px 30px 25px;
    border-radius: 10px;
    background-color: #2f3340;
    margin: auto auto;
`;

const AnimatedGradientText = styled.h1`
    background-image: linear-gradient(45deg, #695ee6 0%, #ff7a7a 85%);
    background-size: 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 60px;
    font-weight: 600;
    overflow: hidden;
    font-link: Poppins;

    @media (max-width: 767px) {
        font-size: 36px;
    }

    /* Adjust font size for tablets */
    @media (min-width: 768px) and (max-width: 1023px) {
        font-size: 45px;
    }

    /* Adjust font size for larger screens */
    @media (min-width: 1024px) {
        font-size: 60px;
    }
`;

const AnimatedGradientText1 = styled.h1`
    background: linear-gradient(to right, #e42f92, #f08e60, #2193b0, #54249b);
    background-size: 300%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 35px;
    overflow: hidden;
    font-link: Poppins;
    margin: 0;
    padding: 0;
`;

const AnimatedGradientText2 = styled.p`
    font-link:Merriweather
    font-size: 22px;
    font-weight: 300;
    text-align: center;
    color: #fff;
    margin: 0;
    padding: 0;
    @media (max-width: 767px) {
        font-size: 18px;
    }

    /* Adjust font size for tablets */
    @media (min-width: 768px) and (max-width: 1023px) {
        font-size: 22px;
    }

    /* Adjust font size for larger screens */
    @media (min-width: 1024px) {
        font-size: 24px;
    }
`;

const DottedLine = styled.div`
    height: 100px;
    width: 5px;
    position: relative;

    :before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 5px;
        background: repeating-linear-gradient(to bottom, transparent 1px, transparent 5px, #c685d6 5px, #c685d6 10px);
    }
`;

const AnimatedButton = styled.button`
    transition: all 0.3s ease-in-out;
    border-radius: 5;
    border: "none";
    background: linear-gradient(45deg, #695ee6 0%, #ff7a7a 85%);
    color: "#fff";
    font-weight: "600";
    letter-spacing: "1px";
    font-size: 12;
    padding: 12;
    cursor: "pointer";
    &:hover {
        padding: 17;
    }
`;
